<template>
  <div
    class="bounding-box"
    :class="{
      'bounding-box--active': highlighted,
      [`bounding-box-${label}`]: true,
    }"
    :style="boundingBoxStyle"
    v-on="$listeners"
  >
    <span class="bounding-box__label">
      <slot>
        {{ getFormattedLabel(label) }}
      </slot>
    </span>
    <slot name="action"></slot>
  </div>
</template>

<script lang="ts">
import Vue, { type PropType } from "vue"
import type { DetectionLabel } from "@evercam/shared/types"
import { stringToColor, toTitleCase } from "@evercam/shared/utils"

export default Vue.extend({
  name: "BoundingBox",
  props: {
    label: {
      type: String as PropType<DetectionLabel>,
      required: true,
    },
    xMin: {
      type: Number,
      required: true,
    },
    yMin: {
      type: Number,
      required: true,
    },
    xMax: {
      type: Number,
      required: true,
    },
    yMax: {
      type: Number,
      required: true,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    boundingBoxStyle(): Record<string, string> {
      const coords = {
        left: this.xMin,
        top: this.yMin,
        height: this.yMax - this.yMin,
        width: this.xMax - this.xMin,
      }

      return {
        "--box-color": stringToColor(this.label),
        top: `${coords.top * 100}%`,
        left: `${coords.left * 100}%`,
        height: `${coords.height * 100}%`,
        width: `${coords.width * 100}%`,
        transition: "width 0.2s, height 0.2s",
      }
    },
  },
  beforeDestroy() {
    this.$emit("destroy-object-path")
  },
  methods: {
    getFormattedLabel(label: string): string {
      return toTitleCase(label)
    },
  },
})
</script>

<style scoped lang="scss">
.bounding-box {
  position: absolute;
  user-select: none;

  &::after,
  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid var(--box-color);
    box-shadow: 0 0 5px 0 var(--box-color);
    z-index: 10000;
  }
  &:hover::before,
  &--active::before {
    border-width: 0;
  }
  &:hover::after,
  &--active::after {
    animation: border-highlight 0.2s alternate infinite;
  }
  &:hover .bounding-box__label,
  &.bounding-box--active .bounding-box__label {
    text-shadow: -1px -1px 1px var(--box-color), 1px -1px 1px var(--box-color),
      -1px 1px 1px var(--box-color), 1px 1px 1px var(--box-color);
    color: #333;
  }

  &__label {
    color: var(--box-color);
    position: absolute;
    user-select: none;
    top: -1.5rem;
    font-size: 1rem;
    text-shadow: -1px -1px 1px #333, 1px -1px 1px #333, -1px 1px 1px #333,
      1px 1px 1px #333;
    overflow: visible;
    font-weight: 500;
    letter-spacing: 1.5px;
    white-space: nowrap;
  }
}

@keyframes border-highlight {
  25% {
    border-width: 2px;
  }
  50% {
    border-width: 3px;
  }
  100% {
    border-width: 4px;
  }
}
</style>
