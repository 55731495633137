<template>
  <ValidationObserver>
    <ValidationProvider
      v-slot="{ errors }"
      :name="$attrs.label"
      :rules="rules"
      :vid="vid"
    >
      <component
        :is="isCombobox ? 'v-combobox' : 'v-autocomplete'"
        v-model="selectedItem"
        :items="items"
        :loading="isLoading"
        :search-input.sync="itemSearch"
        :error-messages="errors"
        :item-text="getProp('item-text', 'name')"
        :hide-no-data="getProp('hide-no-data', !isCombobox)"
        :return-object="getProp('return-object', true)"
        :outlined="getProp('outlined', true)"
        :dense="getProp('dense', true)"
        :hide-details="getProp('hide-details', false)"
        :class="getProp('classes', '')"
        :cache-items="getProp('cache-items', true)"
        :append-icon="getProp('append-icon', '')"
        :placeholder="getProp('placeholder', 'Start typing to Search')"
        :menu-props="{ closeOnClick: true, overflowY: true }"
        v-bind="$attrs"
        v-on="$listeners"
        @change="itemSearch = ''"
        @focus="onFocus"
        @blur="onBlur"
      >
        <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>

        <template #label>
          <slot name="label" />
        </template>

        <template v-if="!!itemSearch" #no-data>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                No results matching "<strong>{{ itemSearch }}</strong
                >". It will be added
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </component>
    </ValidationProvider>
  </ValidationObserver>
</template>

<script>
import { VCombobox, VAutocomplete } from "vuetify/lib"
import { AdminApi } from "@evercam/shared/api/adminApi"
export default {
  components: {
    VCombobox,
    VAutocomplete,
  },
  props: {
    value: {
      type: [String, Number, Object, Array, Boolean],
      default: () => null,
    },
    resource: {
      type: String,
      default: () => "",
    },
    rules: {
      type: [String, Object],
      default: () => "",
    },
    vid: {
      type: String,
      default: null,
    },
    search: {
      type: String,
      default: () => "",
    },
    listItems: {
      type: Array,
      default: () => [],
    },
    provider: {
      type: Function,
      default: async () => {
        return {
          items: [],
        }
      },
    },
    providerParams: {
      type: Function,
      default: (val) => {
        return { term: val }
      },
    },
    searchOnFocus: {
      type: Boolean,
      default: false,
    },
    searchOnMounted: {
      type: Boolean,
      default: false,
    },
    isCombobox: {
      type: Boolean,
      default: false,
    },
    autoSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      isLoading: false,
      itemSearch: null,
    }
  },
  computed: {
    selectedItem: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      },
    },
  },
  watch: {
    async itemSearch(val) {
      if (this.isLoading) return
      if (!this.searchOnFocus && (!val || val.length < 3)) {
        return
      }
      this.loadItems(val)
    },
    search: {
      async handler(val) {
        if (val) {
          await this.loadItems(val)
          this.$emit("on-search-item", this.items)
        }
      },
      immediate: true,
    },
    listItems: {
      handler(val) {
        this.items = val
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.searchOnMounted) {
      this.loadItems()
    }
  },
  methods: {
    getProp(propName, fallback) {
      if (Object.prototype.hasOwnProperty.call(this.$attrs, propName)) {
        return this.$attrs[propName]
      } else {
        return fallback
      }
    },
    async loadItems(val) {
      this.isLoading = true
      try {
        let response
        let params = this.providerParams(val)
        if (this.resource) {
          params.resource = this.resource
          response = await AdminApi.search.search({
            params,
          })
        } else {
          response = await this.provider({ params })
        }
        this.items = response?.items || response
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.fetch_resource_failed", {
            resource: this.$attrs?.label,
          }),
          error,
        })
      }
      this.isLoading = false
    },
    onFocus() {
      if (this.searchOnFocus) {
        this.loadItems()
      }
    },
    onBlur() {
      if (!this.autoSelect || !this.itemSearch || !this.$attrs.filter) {
        return
      }

      const item = this.listItems.find((item) =>
        this.$attrs.filter(item, this.itemSearch)
      )

      if (!item) {
        return
      }

      this.selectedItem = item
    },
  },
}
</script>
