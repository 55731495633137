<template>
  <div class="d-flex flex-column">
    <div
      v-if="isLoading"
      class="d-flex flex-column justify-center align-center pa-4"
    >
      <div class="caption-1">
        {{ $t("content.recordings.brain_tool_processing") }}
      </div>
      <div class="d-flex justify-center py-4">
        <EvercamLoadingAnimation size="FourXl" />
      </div>
    </div>
    <div
      v-else-if="filteredItems && filteredItems.length"
      class="brain-tool-results"
      :style="{ height: sidebarHeight }"
    >
      <span>Model version : {{ modelVersion }}</span>
      <v-treeview
        :open="openIds"
        :items="detectedObjectsTree"
        class="brain-tool-tree"
        open-on-click
        transition
        @update:open="getActiveNodes"
      >
        <template #label="{ item }">
          <div
            v-if="!item.icon"
            class="brain-tool-tree__label"
            :class="item.id"
            @mouseenter="setFocused(item.id, true)"
            @mouseleave="setFocused(item.id, false)"
          >
            {{ toTitleCase(item.name) }}
            <v-icon small class="ml-auto" @click.stop="toggleVisibility(item)">
              {{ visibleItems[item.id] ? "mdi-eye" : "mdi-eye-off" }}
            </v-icon>
          </div>
          <div v-else class="brain-tool-tree__label">
            <ESvgIcon
              :icon="item.icon"
              :height="38"
              class="mt-n1"
              :color="$vuetify.theme.currentTheme.on_background"
            />
            {{ toTitleCase(item.name) }}
            <v-icon small class="ml-auto" @click.stop="toggleVisibility(item)">
              {{ visibleItems[item.id] ? "mdi-eye" : "mdi-eye-off" }}
            </v-icon>
          </div>
        </template>
      </v-treeview>
    </div>
    <div
      v-else
      class="brain-tool-results caption-1"
      :style="{ height: sidebarHeight }"
    >
      {{ $t("content.recordings.brain_tool_no_results") }}
    </div>

    <!-- Bounding boxes -->
    <portal to="evercam-boxes-container">
      <div
        ref="container"
        class="w-100 h-100 e-top-0 position-absolute d-flex align-center justify-center"
      >
        <div class="position-relative h-100 w-100">
          <BoundingBox
            v-for="item in filteredBoxes"
            :key="item.id"
            :x-min="item.box.xmin"
            :x-max="item.box.xmax"
            :y-min="item.box.ymin"
            :y-max="item.box.ymax"
            :label="item.box.label"
            :highlighted="focusedItems[item.id]"
            @mouseenter="setFocused(item.id, true, true)"
            @mouseleave="setFocused(item.id, false, true)"
          >
            {{ toTitleCase(item.name) }}
          </BoundingBox>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import { AiApi } from "@evercam/shared/api/aiApi"
import BoundingBox from "@evercam/shared/components/BoundingBox"
import { toTitleCase } from "@evercam/shared/utils"

export default {
  name: "EvercamObjectDetection",
  components: {
    BoundingBox,
    EvercamLoadingAnimation,
  },
  props: {
    image: {
      type: [String, HTMLImageElement],
      default: "",
    },
    timestamp: {
      type: String,
      default: "",
    },
    isEdgeVideo: {
      type: Boolean,
      default: false,
    },
    cameraId: {
      type: [String, null],
      default: null,
    },
    sidebarHeight: {
      type: String,
      default: "auto",
    },
  },
  data() {
    return {
      isProcessing: false,
      items: [],
      focusedItems: {},
      modelVersion: "",
      openIds: [],
      headers: [
        {
          text: "Detection",
          value: "detection",
        },
        {
          text: "Count",
          value: "count",
        },
      ],
      detectionThresholdLimit: 30,
      isImageLoading: false,
      visibleItems: {},
    }
  },
  computed: {
    filteredItems() {
      return this.items.filter(
        (item) => item.score >= this.detectionThresholdLimit
      )
    },
    isLoading() {
      return this.isProcessing || this.isImageLoading
    },
    detectedObjectsTree() {
      return this.filteredItems.reduce((acc, item) => {
        const parentNode = acc.find((obj) => obj.id === item.label)

        if (parentNode) {
          parentNode.name = `${item.label} (${parentNode.count + 1})`
          parentNode.count++
          parentNode.children.push({
            id: `${item.label}-${parentNode.count}`,
            parentId: item.label,
            name: `${item.label} ${parentNode.count}`,
            box: item,
          })

          return acc
        }

        return [
          ...acc,
          {
            id: item.label,
            name: `${item.label} (1)`,
            icon: item.label,
            count: 1,
            children: [
              {
                id: `${item.label}-1`,
                parentId: item.label,
                name: `${item.label} 1`,
                box: item,
              },
            ],
          },
        ]
      }, [])
    },
    filteredBoxes() {
      return this.detectedObjectsTree.flatMap(({ children }) =>
        children.filter((child) => !!this.visibleItems[child.id])
      )
    },
  },
  watch: {
    image: {
      immediate: true,
      handler(image) {
        if (image?.includes("unavailable.jpg")) {
          this.resetBrainToolData()

          return
        }
        if (!image || (this.isEdgeVideo && !image.includes("data:image"))) {
          return
        }
        this.fetchObjectDetectionsResults()
      },
    },
    isLoading(val) {
      this.$emit("is-processing", val)
    },
    detectedObjectsTree: {
      handler(items) {
        items.forEach((object) => {
          this.$set(this.visibleItems, object.id, true)
          object?.children?.forEach((child) => {
            this.$set(this.visibleItems, child.id, true)
          })
        })
      },
      immediate: true,
    },
  },
  methods: {
    toTitleCase,
    async fetchObjectDetectionsResults() {
      this.isProcessing = true
      this.items = []
      this.focusedItems = {}

      if (!this.image) {
        this.isProcessing = false

        return
      }

      try {
        const payload = {
          cameraExid: this.cameraId,
          ...(this.isEdgeVideo
            ? { snapshotUrl: this.image }
            : { timestamp: this.timestamp }),
        }
        const data = await AiApi.brainTool.getObjectDetectionsResults(payload)
        this.items = data?.detections?.detections.sort((a, b) =>
          Number(a.score) > Number(b.score) ? -1 : 1
        )
        this.modelVersion = data?.modelVersion
      } catch (error) {
        if (
          error.response.data?.details === "Resource not found" ||
          error.response.status === 404
        ) {
          this.$notifications.error({
            text: this.$t("content.fetch_resource_failed", {
              resource: "brainTool results",
            }),
          })
        } else {
          this.$notifications.error({
            text: this.$t("content.fetch_resource_failed", {
              resource: "brainTool results",
            }),
            error,
            notifyOnCliq: true,
            notifyParams: {
              ERROR: error,
              REQUEST_PAYLOAD: {
                cameraId: this.cameraId,
                timestamp: this.timestamp,
              },
              FEATURE: "BrainTool",
            },
          })
        }
      } finally {
        this.isProcessing = false
        this.isImageLoading = false
      }
    },
    setActiveTreeviewItemStyle(index, focused, isBoxHovered) {
      this.$nextTick(() => {
        const treeviewItem = document
          .querySelector(`.${index}`)
          ?.closest(".v-treeview-node__root")

        if (!treeviewItem) {
          return
        }

        if (focused) {
          treeviewItem.classList.add("v-treeview-node__root--active")
          if (isBoxHovered) {
            this.$setTimeout(() => {
              treeviewItem.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
              })
            }, 300)
          }
        } else {
          treeviewItem.classList.remove("v-treeview-node__root--active")
        }
      })
    },
    isFocused(index) {
      return this.focusedItems[index]
    },
    setFocused(index, focused, isBoxHovered) {
      this.focusedItems = {
        ...this.focusedItems,
        [index]: focused,
      }

      this.openIds = [...this.openIds, index.split("-").slice(0, -1).join("-")]
      this.setActiveTreeviewItemStyle(index, focused, isBoxHovered)
    },
    resetBrainToolData() {
      this.items = []
    },
    getActiveNodes(nodes) {
      this.openIds = nodes
    },
    toggleVisibility(item) {
      const newVisibility = !this.visibleItems[item.id]
      this.$set(this.visibleItems, item.id, newVisibility)

      if (item.children) {
        item.children.forEach((child) => {
          this.$set(this.visibleItems, child.id, newVisibility)
        })
      }

      if (item.parentId) {
        const parent = this.detectedObjectsTree.find(
          (obj) => obj.id === item.parentId
        )

        const allChildrenVisible = parent.children.every(
          (child) => this.visibleItems[child.id]
        )

        this.$set(this.visibleItems, item.parentId, allChildrenVisible)
      }
    },
  },
}
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/_variables";

.brain-tool-tree {
  &__label {
    display: flex;
    font-size: 12px;
    align-items: center;
  }
  .v-treeview-node__label {
    position: relative;
  }
  .v-treeview-node__content,
  .v-treeview-node__label {
    height: 30px;
    display: flex;
    align-items: center;
  }
  .brain-tool-tree__label {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .v-treeview-node__toggle {
    ::after,
    ::before {
      height: 20px;
      width: 20px;
    }
  }
  .v-treeview-node__root {
    padding-left: 8px;
    min-height: 30px;
  }
  .v-treeview-node__children .v-treeview-node__root {
    &:hover,
    &--active {
      background-color: rgba(199, 227, 255, 0.79);
      font-weight: 500;
      transition: all 0.5s;
    }
  }
  .v-treeview-node__toggle.v-icon--link {
    font-size: 24px !important;
    color: var(--v-on_background-base) !important;
    height: 5px;
    width: 5px;
  }
  .v-treeview-node__level {
    width: 15px;
  }
  .v-treeview-node__level ~ .v-treeview-node__level {
    width: 0px;
  }
}

.brain-tool-results {
  overflow-y: auto;
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .bounding-box__label {
    font-size: 0.5rem;
    top: -1rem !important;
  }
}
</style>
